import type { RouteObject } from "react-router-dom";
import { paths } from "@configs/routes/paths";

export const userPrivateRoutes: RouteObject = {
  path: paths.userDashboard,
  async lazy() {
    let { PrivateLayout } = await import("@src/pages/private");
    return { Component: PrivateLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { UserDashboard } = await import("@src/pages/private");
        return { Component: UserDashboard };
      },
    },
    // {
    //   path: paths.userDashboard + paths.userAssociationList,
    //   async lazy() {
    //     let { UserAssociationList } = await import("@src/pages/private");
    //     return { Component: UserAssociationList };
    //   },
    // },
  ],
};

export const associationPrivateRoutes: RouteObject = {
  path: paths.associationDashboard,
  async lazy() {
    let { PrivateLayout } = await import("@src/pages/private");
    return { Component: PrivateLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { AssociationDashboard } = await import("@src/pages/private");
        return { Component: AssociationDashboard };
      },
    },
  ],
};
